.rbc-btn-group button {
    background-color: #36BA98;
    color: white;
}






